<template>
  <div>
    <!-- show navbar only if user is logged in -->
    <Navbar />
    <v-container class="dashboard" id="dashboard">
      <h1>Dashboard</h1>
      <v-container class="px-lg-12">
        <v-card class="mb-3">
          <v-card-title>
            <h3 class="">Current performance and data</h3>
          </v-card-title>
          <v-card-text>
            <v-tabs
              v-model="tab"
              background-color="grey lighten-3"
              center-active
            >
              <!-- <v-tabs-slider color="primary"></v-tabs-slider> -->
              <v-tab v-for="(bot, index) in bots" :key="index">
                {{ bot.title }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <!-- eager is very important to reander component on mounted -->
              <v-tab-item eager v-for="(bot, index) in bots" :key="index">
                <v-card flat>
                  <!-- <v-card-title>{{index}}</v-card-title> -->
                  <v-card-text>
                    <!-- Performance Graph -->
                    <canvas
                      :ref="`chartCanvas-${index}`"
                      width="450"
                      height="200"
                      name="perfChart"
                    ></canvas>
                    <!-- <v-divider class="my-3 mx-4"></v-divider> -->
                    <!-- Performance Data -->
                    <!-- <v-data-table
                    :headers="headers"
                    :items="tableData[index]"
                    :items-per-page="20"
                    class="elevation-1 mt-5"
                  >
                  </v-data-table> -->
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
        <!-- currently running Bots -->
        <v-card>
          <v-card-title class="mb-3"><h3>Current Bots</h3></v-card-title>
          <v-card-text>
            <v-layout row wrap justify-center>
              <v-flex xs12 md5 lg3 v-for="(bot, index) in bots" :key="index">
                <v-card :loading="loading" class="mx-sm-3 mb-5">
                  <v-img height="200" src="/img/bots/bot1.jpg"></v-img>
                  <v-card-title>{{ bot.title }}</v-card-title>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-text>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Exchange: {{ bot.exchangeName }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Algorithm: {{ bot.algorithm }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="bot.subaccountName">
                          <v-list-item-content>
                            <v-list-item-title
                              v-if="bot.subaccountName != null"
                            >
                              Subaccount: {{ bot.subaccountName }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Currency: {{ bot.asset }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Capital: {{ bot.balance }} €
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Trade interval: {{ bot.timeInterval }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Created: {{ bot.createdAt.split("T")[0] }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-divider class="mx-4"></v-divider>
                      <div class="text-center mt-3">
                        <v-btn
                          text
                          color="primary"
                          :to="`/performance/${bot.id}`"
                        >
                          <span>Show performance</span>
                          <v-icon right>mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <v-divider class="mx-4 my-3"></v-divider>
            <div class="text-center">
              <v-btn text color="primary" class="mb-3 mt-5" href="#dashboard">
                <span>to the top</span>
                <v-icon right> mdi-arrow-up </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Chart from "chart.js/auto";

export default {
  name: "Home",
  components: {
    Navbar,
  },
  data() {
    return {
      tab: null,
      loading: false,
      bots: [],
      bots_data: [],
      headers: [
        { text: "Datum", value: "time" },
        { text: "Balance", value: "performance" },
        { text: "Benchmark", value: "benchmark" },
        { text: "Asset Price", value: "asset_price" },
      ],
      perf_data: [],
      all_bots_route: process.env.VUE_APP_ALL_BOTS_URL,
    };
  },
  methods: {
    //fetching bots for user
    async fetch_bots(userId) {
      const token = this.$store.getters["user/getTokenValue"];
      try {
        let request = await fetch(
          `${process.env.VUE_APP_ALL_BOTS_URL}${userId}`,
          {
            method: "GET",
            withCredentials: true,
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        if (request.status === 200 || request.status === 401) {
          return await this.formatData(request);
        } else {
          console.log("ERROR");
          //await this.formatData(request);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetch_performance_data(userId) {
      const token = this.$store.getters["user/getTokenValue"];
      try {
        let request = await fetch(
          `${process.env.VUE_APP_ALL_PERFORMANCE_URL}${userId}`,
          {
            method: "GET",
            withCredentials: true,
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            // mode: "no-cors"
          }
        );
        if (request.status === 200 || request.status === 401) {
          return await this.formatData(request);
          // return request.text();
        } else {
          console.log("Could not fetch historical bot data");
          //await this.formatData(request);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async formatData(request) {
      const text = await request.text();
      const data = await JSON.parse(text);
      return data;
    },
    //itterates through all bots and calls printChart for each one
    printCharts() {
      this.bots.forEach((bot, index) => {
        const ref_reference = `chartCanvas-${index}`;
        this.printChart(this.$refs[ref_reference], index);
      });
    },
    //prints a single chart
    printChart(ref, index) {
      new Chart(ref, {
        data: {
          labels: this.printData[index].date,
          datasets: [
            {
              type: "line",
              label: "Balance",
              data: this.printData[index].performance,
              backgroundColor: ["rgba(128, 128, 128, 0.3)"],
              borderColor: ["rgba(128, 128, 128, 0.75)"],
              borderWidth: 2,
              radius: 0,
              pointHitRadius: 2,
            },
            {
              type: "line",
              label: "Benchmark",
              data: this.printData[index].benchmark,
              backgroundColor: ["rgba(12, 53, 175, 0.3)"],
              borderColor: ["rgba(12, 53, 175, 0.75)"],
              borderWidth: 2,
              radius: 0,
              pointHitRadius: 2,
            },
          ],
        },
        options: {
          responsive: true,
          radius: 0,
          scales: {
            y: {
              title: {
                display: true,
                text: "Preis",
              },
              beginAtZero: false,
            },
            x: {
              title: {
                display: true,
                text: "Zeitinterval",
              },
              ticks: {
                stepSize: 10,
                callback: function (val, index) {
                  // Hide the label of every 8th dataset
                  return index % 2 === 0 ? this.getLabelForValue(val) : "";
                },
                color: "black",
                maxRotation: 45,
              },
            },
          },
        },
      });
    },
  },
  computed: {
    //structuring the data for printing it as chart
    printData: function () {
      let botData = [];
      let bots_dates = [];
      let bots_performance = [];
      let bots_benchmark = [];
      let bots_asset_price = [];
      this.perf_data.forEach((array) => {
        array.forEach((row) => {
          bots_dates.push(row.date);
          bots_benchmark.push(row.benchmark);
          bots_performance.push(row.balance);
          bots_asset_price.push(row.asset_price);
        });
        botData.push({
          date: bots_dates,
          benchmark: bots_benchmark,
          performance: bots_performance,
          asset_price: bots_asset_price,
        });
        bots_dates = [];
        bots_performance = [];
        bots_benchmark = [];
        bots_asset_price = [];
      });
      return botData;
    },
  },
  created: async function () {
    const bots = await this.fetch_bots(this.$store.state.user.user.id);
    this.bots = bots.user_bots;
    const hist_perf = await this.fetch_performance_data(
      this.$store.state.user.user.id
    );
    this.perf_data = hist_perf.data;
    this.printCharts();
  },
};
</script>
<style>
.dashboard {
  scroll-behavior: smooth;
}
</style>